<template>
  <div>
    <el-dialog
      :title="item.id?'编辑仓库':'新增仓库'"
      :visible.sync="visible"
      append-to-body
      v-dialogDrag
      width="50%"
      :close-on-click-modal="false"
    >
      <el-form :model="form" status-icon :rules="rules" ref="form" label-width="60px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="编号" prop="storeCode">
              <el-input v-model="form.storeCode" placeholder="仓库编号" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="名称" prop="storeName">
              <el-input v-model="form.storeName" placeholder="仓库名称" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="状态">
              <el-switch
                v-model="form.isStop"
                active-color="red"
                inactive-color="green"
                active-text="停用"
                inactive-text="正常"
              ></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="位置">
          <el-input v-model="form.storeLocation" placeholder="位置" clearable></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="form.remark" placeholder="备注" type="textarea" :rows="3" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group>
            <el-button type="info" @click="save(false)" :loading="loading">保存关闭</el-button>
            <el-button
              type="success"
              @click="save(true)"
              :loading="loading"
              v-if="!item.id"
            >保存新增</el-button>
          </el-button-group>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {
      visible: false,
      loading: false,
      form: {},
      rules: {
        storeCode: [{ required: true, message: "编号不能为空" }],
        storeName: [{ required: true, message: "名称不能为空" }]
      }
    };
  },
  watch: {
    item: {
      handler: function(value) {
        if (value.id) {
          this.loading = true;
          this.$get("Store/Detail", {
            id: value.id
          })
            .then(r => {
              this.form = r;
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.reset();
        }
        this.visible = true;
      },
      deep: true
    }
  },
  methods: {
    reset() {
      this.form = {
        isStop: false
      };
      if (this.$refs["form"]) {
        this.$refs["form"].resetFields();
      }
    },
    save(isContinue) {
      this.$refs["form"].validate(valid => {
        if (!valid) {
          return;
        }
        this.loading = true;
        let values = this.form;
        if (values.id) {
          this.$post("Store/EditStore", values)
            .then(r => {
              this.$message.success("操作成功！");
              this.$store.commit("updateBaseInfo", {
                info: "Store",
                item: r
              });
              this.visible = false;
              this.$emit("success");
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.$post("Store/AddStore", values)
            .then(r => {
              this.$message.success("操作成功！");
              this.$store.commit("addBaseInfo", {
                info: "Store",
                item: r
              });
              if (isContinue) {
                this.reset();
              } else {
                this.visible = false;
              }
              this.$emit("success", r.id);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    }
  }
};
</script>

<style>
</style>