<template>
  <div>
    <el-dialog
      title="采购价格查询"
      :visible.sync="visible"
      append-to-body
      v-dialogDrag
      width="80%"
      :close-on-click-modal="false"
    >
      <el-form size="mini">
        <el-form-item label="供应商">
          <BaseSelect info="Supplier" v-model="supplierId" @input="getData"></BaseSelect>
        </el-form-item>
      </el-form>

      <el-table :data="data" stripe border max-height="345" v-loading="loading">
        <el-table-column label="编号" prop="billCode" show-overflow-tooltip></el-table-column>
        <el-table-column label="日期" prop="billDate" show-overflow-tooltip sortable>
          <template slot-scope="scope">{{scope.row.billDate|datetime}}</template>
        </el-table-column>
        <el-table-column label="供应商" prop="supplierName" show-overflow-tooltip></el-table-column>
        <el-table-column label="单价" prop="price" show-overflow-tooltip sortable>
          <template slot-scope="scope">{{scope.row.price|thousands}}</template>
        </el-table-column>
        <el-table-column label="数量" prop="quantity" show-overflow-tooltip sortable>
          <template slot-scope="scope">{{scope.row.quantity|thousands}}</template>
        </el-table-column>
        <el-table-column label="金额" prop="amount" show-overflow-tooltip sortable>
          <template slot-scope="scope">{{scope.row.amount|thousands}}</template>
        </el-table-column>
        <el-table-column label="备注" prop="remark" show-overflow-tooltip></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import BaseSelect from "@/components/BaseSelect.vue";
import setName from "@/common/setName";
export default {
  props: ["item"],
  components: {
    BaseSelect
  },
  data() {
    return {
      visible: false,
      supplierId: "",
      goodsId: "",
      data: [],
      loading: false
    };
  },
  watch: {
    item: {
      handler: function(params) {
        this.supplierId = params.supplierId;
        this.goodsId = params.goodsId;
        this.getData();
        this.visible = true;
      },
      deep: true
    }
  },
  methods: {
    getData() {
      this.loading = true;
      this.data = [];
      this.$get("Purchase/GetPrice", {
        goodsId: this.goodsId,
        supplierId: this.supplierId
      })
        .then(r => {
          r.forEach(item => {
            setName("Supplier", item, "supplierId", "supplierName");
            item.amount = new Number(
              (item.price * item.quantity).toFixed(2)
            ).valueOf();
          });
          this.data = r;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style>
</style>