<template>
  <div>
    <el-dialog
      title="物资明细"
      :visible.sync="visible"
      append-to-body
      v-dialogDrag
      width="60%"
      :close-on-click-modal="false"
    >
      <el-form :model="form" status-icon :rules="rules" ref="form" label-width="60px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="名称" prop="goodsId">
              <base-select @input="setGoodsInfo" info="Goods" v-model="form.goodsId"></base-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="规格">
              <el-input v-model="form.goodsSpec" readonly="readonly" placeholder="规格"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="单位">
              <el-input v-model="form.goodsUnit" readonly="readonly" placeholder="单位"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="数量" prop="quantity">
              <el-input
                type="number"
                v-model="form.quantity"
                placeholder="数量"
                clearable
                @input="setAmount"
                @mousewheel.native.prevent
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="单价" prop="price">
              <el-popover ref="price" placement="top-start" trigger="focus">
                <span style="color: teal;">{{form.price|money}}</span>
              </el-popover>
              <el-input
                v-popover:price
                type="number"
                v-model="form.price"
                placeholder="单价"
                clearable
                @input="setAmount"
                @mousewheel.native.prevent
              >
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="金额">
              <el-popover ref="amount" placement="top-start" trigger="focus">
                <span style="color: teal;">{{form.amount|money}}</span>
              </el-popover>
              <el-input
                v-popover:amount
                type="number"
                v-model="form.amount"
                placeholder="金额"
                clearable
                @change="setPriceOrQuantity"
                @mousewheel.native.prevent
              >
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="备注">
          <el-input v-model="form.remark" placeholder="备注" type="textarea" :rows="2" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group>
            <el-button type="info" @click="save(false)">保存关闭</el-button>
            <el-button type="success" @click="save(true)">保存新增</el-button>
            <el-button v-show="form.goodsId" type="warning" @click="getPrice">价格查询</el-button>
          </el-button-group>
        </el-form-item>
      </el-form>
    </el-dialog>
    <div>
      <SupplierPrice :item="priceItem"></SupplierPrice>
    </div>
  </div>
</template>

<script>
import guid from "@/common/guid.js";
import BaseSelect from "@/components/BaseSelect.vue";
import SupplierPrice from "@/views/purchase/SupplierPrice";
export default {
  props: ["item", "supplierId"],
  components: {
    BaseSelect,
    SupplierPrice
  },
  data() {
    return {
      visible: false,
      form: {},
      rules: {
        goodsId: [this.$g.required],
        quantity: [this.$g.required, this.$g.notZero],
        price: [this.$g.required, this.$g.positive]
      },
      priceItem: {}
    };
  },
  watch: {
    item: {
      handler: function(row) {
        if (row.id) {
          this.form = { ...row };
        } else {
          this.initForm();
        }
        this.visible = true;
      },
      deep: true
    }
  },
  methods: {
    initForm() {
      this.form = {
        id: guid(),
        goodsId: "",
        goodsCode: "",
        goodsName: "",
        goodsSpec: "",
        goodsUnit: "",
        quantity: null,
        price: null,
        amount: null,
        remark: "",
        apportionAmount: null
      };
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    setGoodsInfo(goodsId) {
      if (goodsId) {
        this.$get("Goods/GetGoodsInfoById", {
          id: goodsId
        }).then(r => {
          this.form.goodsCode = r.goodsCode;
          this.form.goodsName = r.goodsName;
          this.form.goodsSpec = r.goodsSpec;
          this.form.goodsUnit = r.goodsUnit;
          this.form.price = r.goodsPrice;
          this.setAmount();
        });
      } else {
        this.form.goodsCode = "";
        this.form.goodsName = "";
        this.form.goodsSpec = "";
        this.form.goodsUnit = "";
        this.form.price = null;
        this.setAmount();
      }
    },
    save(isContinue) {
      this.$refs["form"].validate(valid => {
        if (!valid) {
          return;
        }
        this.$emit("success", this.form);

        if (isContinue) {
          this.initForm();
        } else {
          this.visible = false;
        }
      });
    },
    getPrice() {
      this.priceItem = {
        goodsId: this.form.goodsId,
        supplierId: this.supplierId
      };
    },
    setAmount() {
      let quantity = Number(this.form.quantity);
      let price = Number(this.form.price);
      this.form.amount = this.amountFormat(quantity * price);
    },
    setPriceOrQuantity() {
      let amount = Number(this.form.amount);
      let quantity = Number(this.form.quantity);
      let price = Number(this.form.price);
      if (quantity != 0) {
        this.form.price = amount / quantity;
        return;
      }
      if (price != 0) {
        this.form.quantity = amount / price;
        return;
      }
      this.form.amount = 0;
    }
  }
};
</script>

<style>
</style>