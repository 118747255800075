import oss from '@/common/oss'
import store from '@/store'
import axios from 'axios';
import moment from "moment";
import {
    Message,
    MessageBox
} from 'element-ui';
/**
 * 
 * @param {*} savePrint 是否为保存完毕后传入
 * @param {*} billName 汉字
 * @param {*} bill 主表
 * @param {*} detail 明细
 */
const print = function (savePrint, billName, bill, detail) {
    let item = store.state.userInfo.printSet.find(r => r.billName == billName);
    if (!item) {
        Message.info('暂未实现')
        return;
    }
    //保存不打印直接退出
    if (savePrint && !item.savePrint) {
        return
    }
    let key = item.selfTemplate ? store.state.userInfo.companyId + '/' : '';
    key += billName + '.grf';
    oss('zxhd-print').then((client) => {
        let url = client.signatureUrl(key);
        axios.get(url).then(res => {
            if (res.status != 200) {
                Message.error('打印模板获取失败')
            } else {
                let template = res.data;
                let data = getPrintData(billName, bill, detail);
                setPrint(template, data)
            }

        }).catch(error => {
            Message.error('打印模板获取失败')
        })
    })
}
const setPrint = function (template, data) {
    let ws = new WebSocket("ws://127.0.0.1:8181");
    ws.onopen = () => {
        let msg = JSON.stringify({
            templet: JSON.stringify(template),
            data: JSON.stringify(data)
        });
        ws.send(msg);
        ws.close();
    }
    ws.onerror = error => {
        let html = `
        <p style="color:red">打印插件未启动！</p>
        <p>如果已经安装，<a href="zxhdprint://">点击这里</a> 启动</p>
        <p>如果未安装，<a href="https://zxhd-print.oss-cn-beijing.aliyuncs.com/Setup.msi">点击这里</a> 下载插件安装程序</p>
        `
        MessageBox.alert(html, '提示', {
            dangerouslyUseHTMLString: true
        })
    }
}
const getPrintData = function (billName, bill, detail) {
    let Master;
    let Detail = [];
    switch (billName) {
        case "物资采购":
            Master = {
                单据编号: bill.billCode,
                单据日期: moment(bill.billDate).format('YYYY-MM-DD'),
                单据类型: bill.billType == 1 ? "项目采购" : "仓库采购",
                仓库项目: getBaseInfoName(bill.billType == 1 ? "Project" : "Store", bill.locationId),
                供应商: getBaseInfoName("Supplier", bill.supplierId),
                联系人: bill.linkMan,
                联系电话: bill.linkTel,
                银行账号: bill.bankNo,
                摘要: bill.remark,
                操作员: getBaseInfoName("Operator", bill.operatorId),
                分摊方式: getApportionType(bill.apportionType),
                分摊金额: bill.apportionAmount,
                计入应付: getApportionToBalance(bill.apportionToBalance)
            };
            detail.forEach(item => {
                Detail.push({
                    编号: item.goodsCode,
                    名称: item.goodsName,
                    规格: item.goodsSpec,
                    单位: item.goodsUnit,
                    数量: item.quantity,
                    单价: item.price,
                    金额: item.amount,
                    分摊金额: item.apportionAmount,
                    备注: item.remark
                })
            })
            break;
        case '组装加工':
            Master = {
                单据编号: bill.billCode,
                单据日期: moment(bill.billDate).format('YYYY-MM-DD'),
                产品仓库: getBaseInfoName("Store", bill.storeId),
                产品: getBaseInfoName("Goods", bill.goodsId),
                产品数量: bill.quantity,
                加工费用: bill.expenseAmount,
                摘要: bill.remark,
                操作员: getBaseInfoName("Operator", bill.operatorId),
            };
            detail.forEach(item => {
                Detail.push({
                    仓库: item.storeName,
                    物资编码: item.goodsCode,
                    物资名称: item.goodsName,
                    规格: item.goodsSpec,
                    单位: item.goodsUnit,
                    数量: item.quantity,
                    备注: item.remark
                })
            })
            break;
        case '物资调配':
            Master = {
                单据编号: bill.billCode,
                单据日期: moment(bill.billDate).format('YYYY-MM-DD'),
                调配类型: getAllottedType(bill.allottedType),
                发出地: getBaseInfoName((bill.allottedType == 1 || bill.allottedType == 2) ? "Store" : "Project", bill.sourceId),
                接收地: getBaseInfoName((bill.allottedType == 2 || bill.allottedType == 3) ? "Store" : "Project", bill.targetId),
                摘要: bill.remark,
                操作员: getBaseInfoName("Operator", bill.operatorId),
            };
            detail.forEach(item => {
                Detail.push({
                    编号: item.goodsCode,
                    名称: item.goodsName,
                    规格: item.goodsSpec,
                    单位: item.goodsUnit,
                    数量: item.quantity,
                    备注: item.remark
                })
            })
            break;
    }
    return {
        Master,
        Detail
    }
}
const getBaseInfoName = function (type, id) {
    let item = store.state.baseinfo[type].find(r => r.id == id);
    if (item) {
        return item.name;
    } else {
        return ''
    }
}
const getApportionType = function (apportionType) {
    if (!apportionType) {
        return null
    }
    if (apportionType == 1) {
        return '按数量'
    } else {
        return '按金额'
    }
}
const getApportionToBalance = function (apportionToBalance) {
    if (apportionToBalance == null) {
        return null
    }
    if (apportionToBalance) {
        return '计入'
    } else {
        return '不计入'
    }

}
const getAllottedType = function (allottedType) {
    //1仓库到项目2仓库到仓库3项目到仓库4项目到项目
    let res = ''
    switch (allottedType) {
        case 1:
            res = '仓库到项目';
            break;
        case 2:
            res = '仓库到仓库';
            break;
        case 3:
            res = '项目到仓库';
            break;
        case 4:
            res = '项目到项目';
            break;
    }
    return res;
}
export default print