<template>
  <div>
    <el-dialog
      title="物资明细"
      :visible.sync="visible"
      append-to-body
      v-dialogDrag
      width="80%"
      :close-on-click-modal="false"
      @closed="closed"
    >
      <el-form size="mini" :inline="true">
        <el-form-item label="物资类别">
          <type-cascader v-model="filter.goodsTypeId" info="GoodsType"></type-cascader>
        </el-form-item>
        <el-form-item label="过滤">
          <el-input v-model="filter.text" placeholder="输入关键字搜索" clearable></el-input>
        </el-form-item>
        <el-form-item label="总条数">
          <el-tag type="success">{{totalCount}}</el-tag>
        </el-form-item>
        <el-form-item>
          <el-button type="info" @click="save">选定</el-button>
        </el-form-item>
      </el-form>

      <el-table :data="array" :row-class-name="tableRowClassName" stripe border v-loading="loading">
        <el-table-column type="index"></el-table-column>
        <el-table-column label="编号" prop="goodsCode" show-overflow-tooltip></el-table-column>
        <el-table-column label="名称" prop="goodsName" show-overflow-tooltip></el-table-column>
        <el-table-column label="类别" prop="goodsTypeName" show-overflow-tooltip></el-table-column>
        <el-table-column label="规格" prop="goodsSpec" show-overflow-tooltip></el-table-column>
        <el-table-column label="单位" prop="goodsUnit" show-overflow-tooltip></el-table-column>

        <el-table-column label="数量">
          <template slot-scope="scope">
            <el-input
              type="number"
              v-model="scope.row.quantity"
              @input="setAmount(scope.row)"
              @mousewheel.native.prevent
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="单价">
          <template slot-scope="scope">
            <el-input
              type="number"
              v-model="scope.row.price"
              @input="setAmount(scope.row)"
              @mousewheel.native.prevent
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="金额">
          <template slot-scope="scope">
            <el-input
              type="number"
              v-model="scope.row.amount"
              @change="setPriceOrQuantity(scope.row)"
              @mousewheel.native.prevent
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="备注">
          <template slot-scope="scope">
            <el-input v-model="scope.row.remark"></el-input>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        layout="total, prev, pager, next, jumper"
        :current-page.sync="pageIndex"
        :page-size="10"
        :total="pageTotal"
      ></el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import TypeCascader from "@/components/TypeCascader";
import setName from "@/common/setName";
import guid from "@/common/guid.js";
export default {
  components: {
    TypeCascader
  },
  props: ["item"],
  data() {
    return {
      visible: false,
      loading: false,
      filter: {
        goodsTypeId: "",
        text: ""
      },
      arrayTotal: [],
      pageIndex: 1,
      pageTotal: 0
    };
  },
  watch: {
    item: {
      handler: function(params) {
        this.filter.goodsTypeId = "";
        this.filter.text = "";
        this.pageIndex = 1;
        this.visible = true;
      },
      deep: true
    },
    array: {
      handler: function(list) {
        let ids = [];
        list
          .filter(item => item.hasLoadUnitAndPrice == false)
          .forEach(item => {
            ids.push(item.goodsId);
          });
        if (ids.length > 0) {
          this.loading = true;
          this.$get("Goods/GoodsUnitAndPrice", {
            ids
          })
            .then(r => {
              for (let i = 0; i < r.length; i++) {
                let item = list.find(g => g.goodsId == r[i].id);
                if (item) {
                  item.hasLoadUnitAndPrice = true;
                  item.goodsPrice = r[i].goodsPrice;
                  item.goodsUnit = r[i].goodsUnit;
                  item.price = r[i].goodsPrice;
                }
              }
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }
    }
  },
  computed: {
    array() {
      let temp = this.arrayTotal;
      let text = this.filter.text;
      let goodsTypeId = this.filter.goodsTypeId;
      if (text) {
        temp = temp.filter(item => {
          if (
            item.goodsName.indexOf(text) >= 0 ||
            item.pinYin.indexOf(text.toLowerCase()) >= 0 ||
            item.goodsCode.indexOf(text) >= 0 ||
            (item.goodsSpec && item.goodsSpec.indexOf(text) >= 0)
          ) {
            return true;
          }
        });
      }
      if (goodsTypeId) {
        temp = temp.filter(item => {
          if (item.goodsTypeId == goodsTypeId) {
            return true;
          }
        });
      }
      this.pageTotal = temp.length;
      let start = (this.pageIndex - 1) * 10;
      return temp.slice(start, start + 10);
    },
    totalCount() {
      let res = 0;
      this.arrayTotal.forEach(item => {
        let quantity = Number(item.quantity);
        if (quantity != 0) {
          res++;
        }
      });
      return res;
    }
  },
  activated() {
    let list = [];
    this.$store.state.baseinfo.Goods.forEach(item => {
      let goods = {
        goodsId: item.id,
        pinYin: item.pinYin,
        goodsCode: item.code,
        goodsName: item.name,
        goodsSpec: item.spec,
        goodsTypeId: item.typeId,
        goodsUnit: null,
        goodsPrice: null,
        quantity: null,
        price: null,
        amount: null,
        remark: null,
        hasLoadUnitAndPrice: false
      };
      setName("GoodsType", goods, "goodsTypeId", "goodsTypeName");
      list.push(goods);
    });
    this.arrayTotal = list;
  },
  methods: {
    tableRowClassName({ row }) {
      let quantity = Number(row.quantity);
      if (quantity < 0) {
        return "warning-row";
      } else if (quantity > 0) {
        return "success-row";
      } else {
        return "";
      }
    },
    setAmount(row) {
      let quantity = Number(row.quantity);
      let price = Number(row.price);
      row.amount = this.amountFormat(quantity * price);
    },
    setPriceOrQuantity(row) {
      let amount = Number(row.amount);
      let quantity = Number(row.quantity);
      let price = Number(row.price);
      if (quantity != 0) {
        row.price = amount / quantity;
        return;
      }
      if (price != 0) {
        row.quantity = amount / price;
        return;
      }
      row.amount = 0;
    },
    save() {
      let res = [];
      let error = "";
      this.arrayTotal.forEach(item => {
        let quantity = Number(item.quantity);
        let price = Number(item.price);
        if (price < 0) {
          error = `物资编号【${item.goodsCode}】单价为负值，请更正！`;
        }
        if (quantity != 0) {
          res.push({
            id: guid(),
            goodsId: item.goodsId,
            goodsCode: item.goodsCode,
            goodsName: item.goodsName,
            goodsSpec: item.goodsSpec,
            goodsUnit: item.goodsUnit,
            quantity: quantity,
            price: price,
            amount: item.amount,
            remark: item.remark,
            apportionAmount: null
          });
        }
      });
      if (error) {
        this.$message.error(error);
        return;
      }
      if (res.length == 0) {
        this.$message.error("请选择物资明细");
        return;
      }
      this.$emit("success", res);

      this.visible = false;
    },
    closed() {
      this.arrayTotal.forEach(item => {
        item.quantity = null;
        item.price = item.goodsPrice;
        item.amount = null;
        item.remark = "";
      });
    }
  }
};
</script>

<style>
</style>