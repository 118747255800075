<template>
  <div>
    <el-dialog
      title="采购费用分摊"
      :visible.sync="visible"
      append-to-body
      v-dialogDrag
      width="40%"
      :close-on-click-modal="false"
    >
      <el-form :model="form" status-icon :rules="rules" ref="form" label-width="80px">
        <el-form-item label="分摊方式" prop="apportionType">
          <el-radio-group v-model.number="form.apportionType">
            <el-radio :label="1" border>按数量</el-radio>
            <el-radio :label="2" border>按金额</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="分摊金额" prop="apportionAmount">
          <el-popover ref="apportionAmount" placement="top-start" trigger="focus">
            <span style="color: teal;">{{form.apportionAmount|money}}</span>
          </el-popover>
          <el-input
            v-popover:apportionAmount
            type="number"
            v-model="form.apportionAmount"
            placeholder="分摊金额"
            clearable
            @mousewheel.native.prevent
          >
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="计入应付" prop="apportionToBalance">
          <el-radio-group v-model="form.apportionToBalance">
            <el-radio :label="true" border>计入</el-radio>
            <el-radio :label="false" border>不计入</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button-group>
            <el-button type="info" @click="ok">确定</el-button>
            <el-button type="success" @click="reset">置空</el-button>
          </el-button-group>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {
      visible: false,
      form: {},
      rules: {
        apportionType: [this.$g.required],
        apportionAmount: [this.$g.required, this.$g.moreThanZero],
        apportionToBalance: [this.$g.required]
      }
    };
  },
  watch: {
    item: {
      handler: function(row) {
        this.form = { ...row };
        this.$nextTick(() => {
          this.$refs["form"].clearValidate();
        });
        this.visible = true;
      },
      deep: true
    }
  },
  methods: {
    ok() {
      this.$refs["form"].validate(valid => {
        if (!valid) {
          return;
        }
        this.emit();
      });
    },
    reset() {
      this.form = {
        apportionType: null,
        apportionAmount: null,
        apportionToBalance: null
      };
      this.emit();
    },
    emit() {
      this.visible = false;
      this.$emit("success", { ...this.form });
    }
  }
};
</script>

<style>
</style>