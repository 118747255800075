<template>
  <div>
    <div class="bill-title">物资采购</div>
    <el-form
      :model="form"
      status-icon
      :rules="rules"
      ref="form"
      label-width="80px"
    >
      <el-row>
        <el-col :span="6">
          <el-form-item label="单据编号" prop="billCode">
            <el-input v-model="form.billCode" placeholder="单据编号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="单据日期" prop="billDate">
            <el-date-picker
              style="width: 150px"
              @change="getBillCode(3)"
              v-model="form.billDate"
              placeholder="单据日期"
              :clearable="false"
              :editable="false"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item label="单据类型" prop="billType">
            <el-radio-group v-model.number="form.billType" @change="typeChange">
              <el-radio :label="1" border>项目采购</el-radio>
              <el-radio :label="2" border>仓库采购</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="选择项目"
            v-show="form.billType == 1"
            prop="locationId"
          >
            <project-select
              v-model="form.locationId"
              :canAdd="true"
              :state="[1, 2]"
              :addId="addProjectId"
              @addClick="
                () => {
                  this.projectItem = {};
                }
              "
            ></project-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="选择仓库"
            v-show="form.billType == 2"
            prop="locationId"
          >
            <base-select
              info="Store"
              v-model="form.locationId"
              :canAdd="true"
              @addClick="
                () => {
                  this.storeItem = {};
                }
              "
            ></base-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="费用分摊">
            <el-button
              size="mini"
              type="success"
              icon="el-icon-plus"
              @click="expenseApportion"
            ></el-button>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item label="供应商" prop="supplierId">
            <base-select
              @input="supplierChange"
              info="Supplier"
              v-model="form.supplierId"
              :canAdd="true"
              @addClick="
                () => {
                  this.supplierItem = {};
                }
              "
            ></base-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="联系人">
            <el-input
              v-model="form.linkMan"
              placeholder="联系人"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="联系电话">
            <el-input
              v-model="form.linkTel"
              placeholder="联系电话"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="银行账号">
            <el-input
              v-model="form.bankNo"
              placeholder="银行账号"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item>
            <el-checkbox v-model="builderPay">保存后生成付款单</el-checkbox>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="摘要">
        <el-input v-model="form.remark" placeholder="摘要" clearable></el-input>
      </el-form-item>
      <el-form-item label="单据附件">
        <BillAttach :attachId="form.attachId"></BillAttach>
      </el-form-item>
      <el-form-item>
        <template slot="label">
          <strong>物资明细</strong>
        </template>
        <el-button-group>
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-plus"
            @click="detail = {}"
          ></el-button>
          <el-button size="mini" type="success" @click="multiple = {}">
            <span class="iconfont iconduoxuan" style="font-size: 12px"></span>
          </el-button>
        </el-button-group>
      </el-form-item>
      <div style="margin: -8px 0px 8px 80px">
        <el-table
          :data="detailList"
          @row-dblclick="editDetail"
          :row-class-name="tableRowClassName"
          stripe
          border
        >
          <el-table-column
            type="index"
            width="50"
            label="序号"
          ></el-table-column>
          <el-table-column
            label="编号"
            prop="goodsCode"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="名称"
            prop="goodsName"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="规格"
            prop="goodsSpec"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="单位"
            prop="goodsUnit"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="数量">
            <template slot-scope="scope">{{
              scope.row.quantity | thousands
            }}</template>
          </el-table-column>
          <el-table-column label="单价">
            <template slot-scope="scope">{{
              scope.row.price | thousands
            }}</template>
          </el-table-column>
          <el-table-column label="金额" prop="amount">
            <template slot-scope="scope">{{
              scope.row.amount | thousands
            }}</template>
          </el-table-column>
          <el-table-column label="分摊金额" prop="apportionAmount">
            <template slot-scope="scope">{{
              scope.row.apportionAmount | thousands
            }}</template>
          </el-table-column>
          <el-table-column
            label="备注"
            prop="remark"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="操作" width="65">
            <template slot-scope="scope">
              <el-tag
                v-if="!scope.row.isStop"
                type="danger"
                effect="dark"
                @click="deleteDetail(scope.row)"
                >移除</el-tag
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <el-form-item>
        <div v-if="canntEditMsg" style="display: inline">
          <span style="color: red; margin-right: 10px"
            >{{ canntEditMsg }} 单据不可编辑</span
          >
          <el-button type="primary" @click="print(false)" v-if="form.id"
            >单据打印</el-button
          >
        </div>

        <el-button-group v-else>
          <el-button type="info" @click="save(false)">保存关闭</el-button>
          <el-button type="success" @click="save(true)">保存新增</el-button>
          <el-button type="warning" @click="cancel">取消关闭</el-button>
          <el-button type="danger" @click="deleteBill" v-if="form.id&&hasPermission('DeleteBill')"
            >删除单据</el-button
          >
          <el-button type="primary" @click="print(false)" v-if="form.id"
            >单据打印</el-button
          >
        </el-button-group>

        <span style="margin-left: 10px">
          采购金额
          <el-tag type="warning">{{ totalAmount | thousands }}</el-tag
          >元 费用分摊
          <el-tag type="warning">{{ form.apportionAmount | thousands }}</el-tag
          >元 总计
          <el-tag type="success">{{
            (totalAmount + Number(form.apportionAmount)) | thousands
          }}</el-tag
          >元
        </span>
      </el-form-item>
      <el-form-item>
        <operator-box :item="form"></operator-box>
      </el-form-item>
    </el-form>

    <div>
      <PaymentList :billId="form.id"></PaymentList>
    </div>
    <div>
      <add-supplier
        :item="supplierItem"
        @success="
          (id) => {
            this.form.supplierId = id;
            this.supplierChange(id);
          }
        "
      ></add-supplier>
      <add-project
        :item="projectItem"
        @success="
          (id) => {
            this.form.locationId = id;
          }
        "
      ></add-project>
      <add-store
        :item="storeItem"
        @success="
          (id) => {
            this.form.locationId = id;
          }
        "
      ></add-store>
      <goods-detail
        :item="detail"
        @success="updateDetail"
        :supplierId="form.supplierId"
      ></goods-detail>
      <multiple-detail :item="multiple" @success="addArray"></multiple-detail>
      <expense-apportion
        :item="expenseItem"
        @success="addApportion"
      ></expense-apportion>
    </div>
  </div>
</template>

<script>
import billcode from "@/common/billcodeMixins.js";
import BaseSelect from "@/components/BaseSelect.vue";
import ProjectSelect from "@/components/ProjectSelect.vue";
import AddSupplier from "@/views/supplier/Item";
import AddProject from "@/views/project/Item.vue";
import AddStore from "@/views/store/Item";

import GoodsDetail from "@/views/purchase/GoodsDetail.vue";
import MultipleDetail from "./MultipleDetail.vue";

import ExpenseApportion from "./Apportion";
import addPay from "@/common/addPay.js";

import PaymentList from "@/components/PaymentList";
import OperatorBox from "@/components/OperatorBox";
import billPrint from "@/common/billPrint";
import guid from "@/common/guid.js";
import BillAttach from "@/components/BillAttach";
export default {
  mixins: [billcode],
  components: {
    AddSupplier,
    AddProject,
    AddStore,
    BaseSelect,
    ProjectSelect,
    GoodsDetail,
    ExpenseApportion,
    MultipleDetail,
    PaymentList,
    OperatorBox,
    BillAttach,
  },
  data() {
    return {
      routeTab: "物资采购",
      form: {},
      rules: {
        billCode: [this.$g.required],
        billDate: [this.$g.required],
        supplierId: [this.$g.required],
        billType: [this.$g.required],
        locationId: [this.$g.required],
      },
      detailList: [],
      detail: {},
      multiple: {},

      addProjectId: null, //不可用项目Id(编辑时要显示)
      stopList: [], //停用基础信息
      canntEditMsg: "", //不可编辑的提示信息

      supplierItem: {},
      projectItem: {},
      storeItem: {},
      expenseItem: {},

      builderPay: false,
      isContinue: false,
    };
  },
  computed: {
    totalAmount() {
      let amount = 0;
      this.detailList.forEach((item) => {
        amount += this.amountFormat(item.amount);
      });
      return this.amountFormat(amount);
    },
  },
  mounted() {
    this.init(this.$route.query.id);
  },
  beforeDestroy() {
    this.stopList.forEach((item) => {
      this.$store.commit("removeBaseInfo", {
        info: item.info,
        id: item.baseInfo.id,
      });
    });
  },
  methods: {
    //初始化
    init(id) {
      this.builderPay = false;

      this.addProjectId = null;
      this.stopList = [];
      this.canntEditMsg = "";

      if (id) {
        this.editInit(id);
      } else {
        this.addInit();
      }
    },
    //新增初始化
    addInit() {
      this.form = {
        billType: 1,
        billCode: "",
        billDate: new Date(),
        supplierId: "",
        linkTel: "",
        linkMan: "",
        bankNo: "",
        locationId: "",
        apportionType: null,
        apportionAmount: null,
        apportionToBalance: null,
        attachId: guid(),
      };
      this.detailList = [];
      this.getBillCode(3);
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    //编辑初始化
    editInit(id) {
      this.$get("Purchase/Detail", {
        id,
      }).then((r) => {
        this.routeTab = r.bill.billCode;
        this.form = { ...r.bill };
        this.detailList = [...r.detail];

        this.addProjectId = r.addProjectId;
        this.stopList = r.stopList;
        this.canntEditMsg = r.canntEditMsg;
        this.stopList.forEach((item) => {
          this.$store.commit("addBaseInfo", {
            info: item.info,
            item: item.baseInfo,
          });
        });
      });
    },
    //采购类型变化，清除locationId
    typeChange() {
      this.$set(this.form, "locationId", "");
    },
    //供应商改变，查询联系人联系电话
    supplierChange(supplierId) {
      if (supplierId) {
        this.$get("Supplier/GetSupplierInfo", {
          supplierId: supplierId,
        }).then((r) => {
          this.form.linkTel = r.linkTel;
          this.form.linkMan = r.linkMan;
          this.form.bankNo = r.bankNo;
        });
      } else {
        this.form.linkTel = "";
        this.form.linkMan = "";
        this.form.bankNo = "";
      }
    },
    //数量负值为红色
    tableRowClassName({ row }) {
      if (row.quantity < 0) {
        return "warning-row";
      } else {
        return "";
      }
    },
    //选择物资后确定
    updateDetail(row) {
      let index = -1;
      this.detailList.forEach((item, i) => {
        if (item.id == row.id) {
          index = i;
        }
      });
      if (index == -1) {
        //添加
        this.detailList.push(row);
      } else {
        //修改
        this.detailList.splice(index, 1, row);
      }
      this.apportion();
    },
    //多选物资
    addArray(array) {
      array.forEach((item) => {
        this.detailList.push(item);
      });
      this.apportion();
    },
    //双击明细，编辑
    editDetail(row) {
      if (row.isStop) {
        return;
      }
      this.detail = { ...row };
    },
    // 移除明细
    deleteDetail(row) {
      let index = this.detailList.findIndex((d) => {
        return d.id == row.id;
      });
      this.detailList.splice(index, 1);
      this.apportion();
    },
    //费用分摊
    expenseApportion() {
      this.expenseItem = {
        apportionType: this.form.apportionType,
        apportionAmount: this.form.apportionAmount,
        apportionToBalance: this.form.apportionToBalance,
      };
    },
    addApportion(item) {
      this.form.apportionType = item.apportionType;
      this.form.apportionAmount = item.apportionAmount;
      this.form.apportionToBalance = item.apportionToBalance;
      this.apportion();
    },
    apportion() {
      let apportionType = this.form.apportionType;
      let apportionAmount = Number(this.form.apportionAmount);

      if (!apportionType || !apportionAmount) {
        this.detailList.forEach((item) => {
          item.apportionAmount = null;
        });
        return;
      }
      let totalAmount = 0;
      let totalQuantity = 0;
      this.detailList.forEach((item) => {
        totalAmount += Number(item.amount);
        totalQuantity += Number(item.quantity);
      });

      let totalAppprtion = 0;
      this.detailList.forEach((item) => {
        // 分摊方式1按数量2按金额
        if (apportionType == 1) {
          item.apportionAmount =
            totalQuantity == 0
              ? 0
              : this.amountFormat(
                  apportionAmount * (Number(item.quantity) / totalQuantity)
                );
          totalAppprtion += item.apportionAmount;
        }
        if (apportionType == 2) {
          item.apportionAmount =
            totalAmount == 0
              ? 0
              : this.amountFormat(
                  apportionAmount * (Number(item.amount) / totalAmount)
                );
          totalAppprtion += item.apportionAmount;
        }
      });
      //有误差，补到最后一个
      let abs = this.amountFormat(apportionAmount - totalAppprtion);
      if (abs != 0 && this.detailList.length > 0) {
        this.detailList[this.detailList.length - 1].apportionAmount += abs;
      }
    },
    //保存
    save(isContinue) {
      this.isContinue = isContinue;
      this.$refs["form"].validate((valid) => {
        if (!valid) {
          return;
        }
        if (this.detailList.length == 0) {
          this.$message.error("请选择物资明细");
          return;
        }

        let loading = this.$loading({
          lock: true,
          text: "保存中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        let bill = { ...this.form };
        let detail = [...this.detailList];
        if (this.form.id) {
          this.$post("Purchase/Edit", {
            bill,
            detail,
          })
            .then((r) => {
              if (this.builderPay) {
                let amount = bill.apportionToBalance
                  ? this.totalAmount + Number(bill.apportionAmount)
                  : this.totalAmount;
                addPay(
                  bill.billDate,
                  bill.supplierId,
                  bill.bankNo,
                  1,
                  r,
                  amount,
                  this.saveCallBack
                );
              } else {
                this.saveCallBack();
              }
            })
            .finally(() => {
              loading.close();
            });
        } else {
          this.$post("Purchase/Add", {
            bill,
            detail,
          })
            .then((r) => {
              if (this.builderPay) {
                let amount = bill.apportionToBalance
                  ? this.totalAmount + Number(bill.apportionAmount)
                  : this.totalAmount;
                addPay(
                  bill.billDate,
                  bill.supplierId,
                  bill.bankNo,
                  1,
                  r,
                  amount,
                  this.saveCallBack
                );
              } else {
                this.saveCallBack();
              }
            })
            .finally(() => {
              loading.close();
            });
        }
      });
    },

    //保存后回调
    saveCallBack() {
      this.$message.success("操作成功！");

      this.print(true);

      if (this.isContinue) {
        this.$tabs.close({
          to: {
            name: "PurchaseItem",
            query: {},
          },
          refresh: true,
        });
      } else {
        this.$tabs.close();
      }
    },
    print(savePrint) {
      let bill = { ...this.form };
      let detail = [...this.detailList];
      billPrint(savePrint, "物资采购", bill, detail);
    },
    //删除单据
    deleteBill() {
      this.$confirm("将删除此采购单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let loading = this.$loading({
            lock: true,
            text: "删除中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          this.$post("Purchase/Delete?id=" + this.form.id)
            .then((r) => {
              this.$g.deleteAttach(this.form.attachId);
              this.$message.success("操作成功！");
              this.$tabs.close();
            })
            .finally(() => {
              loading.close();
            });
        })
        .catch(() => {});
    },
    cancel() {
      if (!this.form.id) {
        this.$g.deleteAttach(this.form.attachId);
      }
      this.$tabs.close();
    },
  },
};
</script>

<style>
</style>